/* Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Poppins&family=Roboto&family=Ubuntu&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.navbar {
  background-color: #a7defc;
}

.section {
  padding-top: 30px;
}

.card-col {
  display: flex;
  margin-bottom: 24px;
}

.subtitle {
  font-style: italic;
  font-size: 16px;
  font-weight: bold;
  color: #818589;
}

.social {
  margin-left: 5px;
  margin-right: 5px;
}

.line {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

.line2 {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: underline; }

#about {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 30px;
}

#projects {
  margin-left: 20%;
  margin-right: 20%;
}

#contact {
  margin-bottom: 30px;
}

.footer {
  background-color: #a7defc;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 12px;
}

.card-body {
  background-color: #a7defc;
}

/* Code for card hover animation */
/* Source: https://codepen.io/Corsurath/pen/abbxNpj */
.card {
  align-items: stretch;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}

.card:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

/* Code for paper plane animation */
/* Source: https://lenadesign.org/2020/04/21/css-paper-plane-animation/ */
.animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.combined {
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 400px;
  background-color: #a7defc;
  border-radius:50%;
}

.plane {
  position: absolute;
  left: 200px;
  top: 150px;
  animation: move 3s linear infinite;
}

@keyframes move {
  0% {transform: translateY(0) rotateX(0);}
  25% {transform: translateY(-30px) rotateX(40deg);}
  50% {transform: rotateX(0) translateY(0);}
  75% {transform: rotateX(-40deg) translateY(30px);}
  100% {transform: rotateX(0) translateY(0);}
}

.wingRight {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 22.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-bottom: 115px solid white;
  transform: rotate(61deg); 
  z-index:4;
}

.bottom {
  position: relative;
  width: 0;
  height: 0;
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-bottom: 25.5px solid #676d70;
  top: 71px;
  transform: rotate(-5deg);
  left:-52.5px;
  z-index:3;
}

.top {
  position:absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 30px solid #c1c7c9;
  top: 65px;
  transform: rotate(5deg);
  left:-60px;
  z-index:1;
}

.middle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 135px solid #c1c7c9;
  top: -13.5px;
  transform: rotate(72deg);
  left:-7.5px;
  z-index:2;
}

.wingLeft {
  position: absolute; 
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-bottom: 135px solid white;
  transform: rotate(78deg) skewY(-35deg); 
  left:-18.5px;
  z-index:3;
  top:-30px;
}

.clouds {
  position: absolute;
  top:50px;
  left:100px;
}

@keyframes cloud {  
  from{ 
    left:-150px; 
  }
  to{ 
    left: 400px; 
  }
}

.cloudOne, .cloudTwo, .cloudThree {
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: #fff;
  border-radius:100px 100px 0 0;
}

.cloudOne {
  top:0;
  left:0;
  animation: cloud 3s linear infinite reverse;
}

.cloudTwo {
  top:50px;
  left:100px;
  animation: cloud 2.5s linear infinite reverse;
}

.cloudThree {
  top:250px;
  left:50px;
  animation: cloud 2.8s linear infinite reverse;
}

.cloudOne:before, .cloudTwo:before, .cloudThree:before {
  content:"";
  position: absolute;
  width: 25px;
  height: 12.5px;
  background-color: #fff;
  border-radius:100px 100px 0 0;
  left:-20px;
  top:12.5px;
  box-shadow: 65px 0 #fff;
}
